import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import { MemberFriends } from "api/member";
import DataTable from "examples/Tables/DataTable";
import { formatter } from "utils/validations";

const columns = [
  { Header: "#", accessor: "no" },
  { Header: "ยูสเซอร์", accessor: "username" },
  { Header: "ชื่อ-สกุล", accessor: "full_name" },
  { Header: "เบอร์โทร", accessor: "phone_number" },
  { Header: "ฝาก", accessor: "sum_deposit" },
  { Header: "ถอน", accessor: "sum_withdraw" },
  { Header: "กำไร/ขาดทุน", accessor: "summary" },
];

function MemberFriendPopup({ member, onClose }) {
  //   const [memberData, setMemberData] = useState();
  //   const [isLoading, setLoading] = useState(false);

  const [friendTableData, setFriendTableData] = useState({ columns, rows: [] });
  const isOpen = true;

  async function getFriendRef() {
    try {
      const response = await MemberFriends({ member_uid: member?.member_uid });
      if (response?.data?.error === 0) {
        const friends = response?.data?.data?.result;
        const newFriends =
          friends &&
          friends.length > 0 &&
          friends.reduce((a, b) => {
            if (b?.username) {
              const news = { ...b };
              const fDeposit = b?.sum_deposit ? parseFloat(b?.sum_deposit) : 0;
              const fWithdraw = b?.sum_withdraw ? parseFloat(b?.sum_withdraw) : 0;

              news.full_name = (
                <SuiTypography component="label" variant="caption">
                  {b?.first_name} {b?.last_name}
                </SuiTypography>
              );
              news.sum_deposit = (
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor="success"
                >
                  {formatter.format(b?.sum_deposit).toString()}
                </SuiTypography>
              );
              news.sum_withdraw = (
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor="error"
                >
                  {formatter.format(b?.sum_withdraw).toString()}
                </SuiTypography>
              );
              const summary = fDeposit - fWithdraw;

              let cColor = "dark";

              if (summary > 0) {
                cColor = "success";
              }
              if (summary < 0) {
                cColor = "error";
              }
              news.summary = (
                <SuiTypography
                  component="label"
                  variant="body2"
                  fontWeight="bold"
                  textColor={cColor}
                >
                  {formatter.format(summary).toString()}
                </SuiTypography>
              );

              a.push(news);
            }
            return a;
          }, []);
        setFriendTableData({ columns, rows: newFriends });

        return;
      }
    } catch (err) {
      ResponseAlert(err?.response);
    }
  }

  useEffect(() => {
    getFriendRef();
  }, []);

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          แนะนำเพื่อน
        </SuiTypography>
        <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
          <Icon color="white">close</Icon>
        </SuiBox>
      </SuiBox>
      <SuiBox className="overflow-scroll">
        <DialogContent>
          <DataTable
            table={friendTableData}
            entriesPerPage={false}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
          />
        </DialogContent>{" "}
      </SuiBox>
    </Dialog>
  );
}

MemberFriendPopup.defaultProps = {
  member: {},
  onClose: () => {},
};

MemberFriendPopup.propTypes = {
  member: PropTypes.shape,
  onClose: PropTypes.func,
};

export default MemberFriendPopup;
