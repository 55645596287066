import { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { format, subHours, addDays, startOfDay, startOfYesterday } from "date-fns";
import { Card, CircularProgress, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { ResponseAlert } from "components/ResponseAlert";
import DataTable from "examples/Tables/DataTable";
import { WithdrawList } from "api/withdraw";
import WithdrawRejectPopup from "./withdraw-reject-popup";
import WithdrawApprovePopup from "./withdraw-approve-popup";

Pusher.logToConsole = false;
const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const pusher = new Pusher(pusherKey, {
  cluster: "ap1",
});

const channel = pusher.subscribe("my-channel");

const columns = [
  { Header: "ยูสเซอร์", accessor: "member_username" },
  { Header: "จำนวน(บาท)", accessor: "amount" },
  { Header: "วันที่/เวลา", accessor: "createdAt" },
  { Header: "ทำรายการ", accessor: "action" },
];

function WithdrawPending() {
  const [withdrawTableData, setWithdrawTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [withdrawSelect, setWithdraw] = useState();
  const [isReject, setOpenReject] = useState(false);
  const [isApprove, setOpenApprove] = useState(false);
  const startDate = format(new Date(), "yyyy-MM-dd");
  const endDate = format(new Date(), "yyyy-MM-dd");

  async function handleRejectWithdraw(withdraw) {
    setWithdraw(withdraw);
    setOpenReject(true);
  }

  async function handleWithdraw(withdraw) {
    setWithdraw(withdraw);
    setOpenApprove(true);
  }

  async function getWithdrawList() {
    setLoading(true);
    const createdAt = {};
    if (startDate) {
      createdAt.from = format(startOfYesterday(), "yyyy-MM-dd HH:mm:ss");
    }
    if (endDate) {
      createdAt.to = format(
        subHours(startOfDay(addDays(new Date(endDate), 1)), 7),
        "yyyy-MM-dd HH:mm:ss"
      );
    }
    try {
      const response = await WithdrawList({ status_code: "pending" });
      console.log("WithdrawPending-getWithdrawList-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const wList = response.data?.data?.result ?? [];
        const newWList = wList.reduce((a, b) => {
          const newB = { ...b };
          newB.action = (
            <SuiBox display="flex" alignItems="center" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                buttonColor="error"
                fullWidth
                onClick={() => handleRejectWithdraw(b)}
              >
                ปฎิเสธ
              </SuiButton>
              <SuiButton
                sx={{ ml: 1 }}
                variant="gradient"
                buttonColor="info"
                onClick={() => handleWithdraw(b)}
              >
                ถอน
              </SuiButton>
            </SuiBox>
          );
          a.push(newB);
          return a;
        }, []);
        setWithdrawTableData({ columns, rows: newWList });
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      setLoading(false);
      ResponseAlert(err.response);
    }
  }

  useEffect(() => {
    getWithdrawList();
  }, []);

  channel.bind(
    "withdrawpending",
    (data) => {
      console.log("pusher-withdrawSuccess-data", data);
      getWithdrawList();
    },
    channel.unbind("withdrawpending")
  );

  return (
    <SuiBox display="flex" width="100%">
      {isApprove && (
        <WithdrawApprovePopup
          withdraw={withdrawSelect}
          onCancel={() => {
            setOpenApprove(false);
            setWithdraw();
          }}
          onFetchPendingWithdraw={() => getWithdrawList()}
        />
      )}
      {isReject && (
        <WithdrawRejectPopup
          withdraw={withdrawSelect}
          onCancel={() => {
            setOpenReject(false);
            setWithdraw();
          }}
          onFetchPendingWithdraw={() => getWithdrawList()}
        />
      )}
      <Grid customClass="overflow-x-scroll" container spacing={2}>
        <Grid item xs={12}>
          <Card>
            {isLoading ? (
              <SuiBox
                p={3}
                lineHeight={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="secondary" />
              </SuiBox>
            ) : (
              <SuiBox p={2} className="overflow-scroll">
                <DataTable
                  table={withdrawTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </SuiBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default WithdrawPending;
